<template>
  <article>
    <section class="content" id="avis">
      <h2>Ils me font confiance</h2>
      <Splide :options="avis" :has-track="false" aria-label="Avis">
        <SplideTrack>
          <SplideSlide>
            <h3>Nathalie B.</h3>
            <p>
              Je porte un bracelet et une pierre en collier, depuis plusieurs
              mois, et ceci font preuve d'une grande efficacité et en plus, sont
              très jolis à porter. Je vous recommande de vous rapprocher de
              Cathy qui sera bien vous conseiller.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Nathalie J.</h3>
            <p>
              J'ai commandé deux bracelets pour différentes utilisations. J'ai
              été contente des produits et des explications de Cathy qui est à
              l'écoute. Je l'ai recommandée à plusieurs personnes, je lui donne
              5 étoiles.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Isabelle</h3>
            <p>Personne agréable et à l'écoute.</p>
          </SplideSlide>
          <SplideSlide>
            <h3>Marie A.</h3>
            <p>
              Ravie de mon chemin de vie, que je porte tous les jours.
              Confection rapide et sur mesure. Cathy est quelqu'un qui est à
              l'écoute et professionnelle, qui prend le temps de nous expliquer
              chaque pierre qui le compose, ses vertus, ses bienfaits et bien
              sûr son entretien. Ce bracelet me reflète parfaitement et je me
              sens plus apaisée depuis.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Marie B.</h3>
            <p>
              Cathy est à l écoute, bienveillante. Les pierres et les
              réalisations des bracelets sont de qualité. Les bienfaits sont
              arrivés très vite.. Merci beaucoup ! Je recommande.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Jocelyne</h3>
            <p>
              Bonjour, ayant acheté à plusieurs reprises des bijoux, j'ai
              toujours été satisfaite des pierres de très bonne qualité ainsi
              que du travail et des conseils de Cathy.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Laurence</h3>
            <p>
              Il y a eu un moment de ma vie ou une amie m’a recommandé Cathy.
              J’ai été très bien accueillie. Personne a l’écoute. J’ai fait
              faire un bracelet. Pierres de très bonnes qualités à un prix
              correct. Et dès qu’il y avait des questions ou des soucis autres
              Cathy était toujours là. Cela m'a bien aidé et je l’ai recommandé
              à plusieurs personnes. Vous pouvez appeler Cathy et avoir besoin
              de ces services avec confiance. Je vous la recommande.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Laetitia</h3>
            <p>
              J’ai fait l’achat d’un chemin de vie, ça fait plus d’un an. J’en
              suis ravie. Je le trouve très joli et il m’est indispensable. Il
              m'accompagne au quotidien pour gérer les émotions, il m apaise.
              Catherine est à l’écoute et de très bons conseils. J’ai été
              surprise de son interprétation qui me correspondait parfaitement.
              Merci Catherine pour votre bienveillance.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Perrine Q-R</h3>
            <p>
              Catherine est une thérapeute en or, avec humanité, bienveillance
              elle sera interpréter votre histoire et vous transmettre de
              magnifiques bracelets ou chemin de vie de qualité et de toutes
              beautés ! Vous pouvez y aller les yeux fermés et le cœur léger !
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Jean-Noël C.</h3>
            <p>
              Bracelet réalisé avec des pierres de qualité à la bonne taille.
              Cathy m'a conseillé pour le recharger chaque mois. Je vous la
              conseille.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Delphine</h3>
            <p>
              Catherine vous accueille avec beaucoup de professionnalisme. Une
              explication sur le choix des pierres qui composent votre bracelet
              chemin de vie est donnée pour chaque pierre choisie. Elle vous
              explique pourquoi telle ou telle pierre est bonne pour vous et ce
              qu’elle va vous apporter. Le résultat est bluffant car il
              correspond vraiment à nos traits de personnalité et à nos points
              sensibles. Je recommande vivement les bracelets de Catherine, que
              ce soit les chemins de vie ou les bracelets thérapeutiques.
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Mireille G.</h3>
            <p>
              Je porte les bracelets de Catherine depuis de nombreux mois. Je ne
              les quittes pas, c'est un signe !!
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Martine</h3>
            <p>
              Catherine écoute avec attention et explique avec simplicité et
              sérénité le choix des pierres. J'ai ressenti très rapidement comme
              une force sereine. Je recommande vivement car les bienfaits ne se
              font pas attendre et pour ne rien gâcher votre poignet est
              joliment embelli de couleurs chatoyantes. 🙏
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>M.C.</h3>
            <p>
              Bracelet thérapeutique très efficace tellement bien et joli que je
              viens d'en commander un second. Personne très agréable et
              compétente, à l'écoute. Délai de confection de l'article très
              rapide. Personne vivement recommandable et de confiance. Merci
              Catherine !
            </p>
          </SplideSlide>
          <SplideSlide>
            <h3>Perrine B.</h3>
            <p>
              Je vous recommande vivement Cathy pour la confection de vos
              bracelets. Mon chemin de vie m’accompagne depuis plus de 2 ans. Je
              ne peux pas m’en séparer, il m’apporte beaucoup... <br />
              Plusieurs autres bracelets confectionnés par Cathy pour différents
              maux ont apporté l'effet attendu. Vous pouvez lui faire confiance
              les yeux fermés. <br />
              Merci Cathy ❤
            </p>
          </SplideSlide>
        </SplideTrack>

        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev">
            <font-awesome-icon icon="fa-solid fa-caret-right" />
          </button>
          <button class="splide__arrow splide__arrow--next">
            <font-awesome-icon icon="fa-solid fa-caret-right" />
          </button>
        </div>

        <ul class="splide__pagination"></ul>
      </Splide>
    </section>
  </article>
</template>

<script>
import { Splide, SplideTrack, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

export default {
  name: "avisComponent",
  data() {
    return {
      avis: {
        type: "loop",
        perPage: 2,
        rewind: true,
        rewindByDrag: true,
        keyboard: true,
        cover: true,
        arrows: true,
        pagination: true,
        gap: "2.5rem",
        breakpoints: {
          899: {
            perPage: 1,
            arrows: false,
          },
        },
      },
    };
  },
  components: {
    Splide,
    SplideTrack,
    SplideSlide,
  },
};
</script>

<style scoped lang="scss">
article {
  margin: var(--content-margin) 0;

  .content {
    margin-top: 0;

    .splide {
      @media (min-width: 900px) {
        padding: 0 3rem;
      }

      .splide__slide {
        background-color: white;
        padding: 1.6rem;
        border-radius: var(--border-radius);
        border: 2px solid black;

        h3 {
          font-size: 1.4em;
          color: black;
          margin-bottom: 2rem;
        }

        p {
          z-index: 0;
          padding: 0 1.4rem;
          text-align: center;
          position: relative;
          font-size: 1.1em;

          &::before,
          &::after {
            z-index: -1;
            position: absolute;
            color: rgba(0, 0, 0, 0.08);
            font-size: 5em;
            line-height: 0;
            font-family: var(--title-font);
          }

          &::before {
            content: "\201C";
            top: 1.4rem;
            left: -0.2rem;
          }

          &::after {
            content: "\201D";
            bottom: -1rem;
            right: -0.2rem;
          }
        }
      }

      .splide__arrow {
        height: 100%;
        border-radius: unset;
        font-size: 1.5em;
        opacity: 1;
        width: 40px;
        background-color: transparent;

        &.splide__arrow--prev {
          left: 0;
        }

        &.splide__arrow--next {
          right: 0;
        }
      }

      .splide__pagination {
        bottom: -2rem;
      }
    }
  }
}
</style>
